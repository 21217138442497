<template>
  <div class="add-payroll">
    <KTCodePreview v-bind:title="'Nhập bảng lương'">
      <template v-slot:preview>
        <!-- Tên - Loại - Tháng -->
        <b-row class="mt-4">
          <b-col md="3">
            <b-form-group class="required-control">
              <label><b>Tên:</b></label>
              <b-form-input
                v-model="name"
                size="sm"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group class="required-control">
              <label><b>Loại:</b></label>
              <b-form-select
                class="select-style"
                v-model="selectedType"
                :options="listType"
                size="sm"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group class="required-control">
              <label><b>Tháng:</b></label>
              <datetime
                class="form-control form-control-sm"
                v-model="selectedMonth"
                :config="dpConfigs.month"
              ></datetime>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- Nhập file -->
        <b-row>
          <b-col cols='12'>
            <UploadFileMulter
              v-on:send-file="getDataFromExcel"
              v-on:clear-file="clearFileExcel"
              :cmdUrl="`human-resources/import-excel/${selectedType}`"
            />
            <span v-if="fileName">Tên file: <b>{{ fileName }}</b>
            </span>
          </b-col>
        </b-row>
        <!-- Xem trước -->
        <div
          id="scroll-table"
          style="display: grid; margin-top: 23px;"
          v-if="importItems.length"
        >
          <b-table
            :fields="fields"
            :items="importItems"
            :small="true"
            :busy="onLoading"
            responsive
            bordered
            hover
            sticky-header
          >
          </b-table>
          <div class="table-footer">
            <tfoot>
              <tr>
                <td colspan="4"><b>Tổng số dòng:</b> {{ importItems.length ? importItems.length - 1 : 0 }}</td>
              </tr>
            </tfoot>
          </div>
        </div>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="font-weight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onSubmit"
            >Lưu</b-button>
            <router-link
              to="/human-resources"
              tag="button"
            >
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
              >Hủy</b-button>
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>

<style lang="scss">
.add-payroll {
  .table-responsive {
    overflow-y: auto;
    max-height: 300px;
  }
  table {
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Datetime from 'vue-bootstrap-datetimepicker';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';
import ApiService from '../../../core/services/api.service';
import { makeToastFaile, makeToastSuccess, convertPrice } from '@/utils/common';

const DEFAULT_FIELDS = [
  { key: 'kind', label: 'Loại dòng' },
  { key: 'employeeCode', label: 'Mã nhân sự' },
  { key: 'fullName', label: 'Họ tên' },
  { key: 'position', label: 'Vị trí' },
  {
    key: 'positionSalary',
    label: 'Lương vị trí',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'insurancePaidSalary',
    label: 'Lương đóng bảo hiểm',
    formatter: (value) => convertPrice(value),
  },
  { key: 'standardWorkingDayHour', label: 'Giờ công chuẩn (1 ngày)' },
  { key: 'standardWorkingHour', label: 'Giờ công chuẩn' },
  {
    key: 'hourlySalary',
    label: 'Lương theo giờ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'realisticWorkingHours',
    label: 'Giờ làm việc theo ca thực tính',
  },
  {
    key: 'baseSalary',
    label: 'Lương hành chính',
    formatter: (value) => convertPrice(value),
  },
  { key: 'workExtraHours', label: 'Giờ làm thêm' },
  {
    key: 'workExtraPay',
    label: 'Lương làm thêm',
    formatter: (value) => convertPrice(value),
  },
  { key: 'holidayOvertimeHours', label: 'Giờ làm thêm lễ' },
  {
    key: 'holidayPay',
    label: 'Lương làm thêm ngày lễ',
    formatter: (value) => convertPrice(value),
  },
  { key: 'overTimeHours', label: 'Giờ tăng ca' },
  {
    key: 'overTimePay',
    label: 'Lương tăng ca',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'holidayHoursPay',
    label: 'Giờ lễ hưởng lương',
    formatter: (value) => convertPrice(value),
  },
  { key: 'workingHoliday', label: 'Công lễ' },
  {
    key: 'holidaySalary',
    label: 'Lương lễ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'dayOffPay',
    label: 'Ngày nghỉ có lương',
    formatter: (value) => convertPrice(value),
  },
  { key: 'leavePay', label: 'Số công chuẩn' },
  {
    key: 'standardWorkingNumber',
    label: 'Lương phép',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'periodSalary',
    label: 'Mức lương kỳ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'positionAllowance',
    label: 'Phụ cấp chức vụ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'attendanceAllowance',
    label: 'Phụ cấp chuyên cần',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'dualJobAllowance',
    label: 'Phụ cấp kiêm nhiệm',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'transportationAllowance',
    label: 'Phụ cấp gửi xe',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'appearanceAllowance',
    label: 'Phụ câp tác phong đẹp',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'customerSatisfactionAllowance',
    label: 'Phụ cấp Khách hàng hài lòng',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'totalIncome',
    label: 'Tổng thu nhập',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'additionAccrual',
    label: 'Phát sinh cộng',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'intoAmount',
    label: 'Thành tiền',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'deductionAccrual',
    label: 'Phát sinh trừ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'personalIncomeTax',
    label: 'Thuế TNCN',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'unionDues',
    label: 'Khấu trừ CĐ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'shuInsuranceDeduction',
    label: 'Khấu trừ BHXH, BHYT, BHTN',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'netPay',
    label: 'Thực nhận',
    formatter: (value) => convertPrice(value),
  },
  { key: 'bankAccountNumber', label: 'Số tài khoản ngân hàng' },
  { key: 'bankAccountName', label: 'Tên tài khoản ngân hàng' },
  { key: 'bankName', label: 'Tên ngân hàng' },
  {
    key: 'totalAmount',
    label: 'Tổng tiền',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'personalSalaryTransferred',
    label: 'Lương chuyển qua TK cá nhân',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'businessSalaryTransferred',
    label: 'Lương chuyển qua TK doanh nghiệp',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'dependent',
    label: 'Người phụ thuộc',
  },
  {
    key: 'selfAllowance',
    label: 'Giảm trừ bản thân',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'holidayLeave',
    label: 'Nghỉ lễ',
  },
  {
    key: 'holidayMoney',
    label: 'Tiền ngày lễ',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'workOnHoliday',
    label: 'Làm ngày lễ',
  },
  {
    key: 'periodicSalary',
    label: 'Mức lương kì',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'supplementSalary',
    label: 'Lương bổ sung',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'nightWorkingHour',
    label: 'Giờ làm việc thực tính ban đêm',
  },
  {
    key: 'nightShiftSalary',
    label: 'Lương ca đêm',
    formatter: (value) => convertPrice(value),
  },
  {
    key: 'taxFreeAmount',
    label: 'Số tiền ko đóng thuế',
    formatter: (value) => convertPrice(value),
  },
];
export default {
  data() {
    return {
      convertPrice,
      targetReportValues: [],
      name: '',
      onLoading: false,
      fields: [],
      fileName: null,
      selectedMonth: null,
      importItems: [],
      file: null,
      selectedType: 1,
      dpConfigs: {
        month: {
          format: 'MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      listType: [
        {
          id: 1,
          name: 'Theo giờ công chuẩn',
        },
        {
          id: 2,
          name: 'Theo ngày công chuẩn làm đêm',
        },
        {
          id: 3,
          name: 'Theo giờ công chuẩn làm ban đêm',
        },
        {
          id: 4,
          name: 'Theo ngày công chuẩn import',
        },
        {
          id: 5,
          name: 'Theo ngày công chuẩn',
        },
        {
          id: 6,
          name: 'Theo miễn chấm công',
        },
        {
          id: 7,
          name: 'Theo báo cáo mục tiêu',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
    Datetime,
    UploadFileMulter,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bảng lương', route: '/human-resources' },
      { title: 'Nhập bảng lương', route: '/import-payroll' },
    ]);
  },
  methods: {
    validate() {
      let result = {
        isValid: true,
        message: '',
      };

      if (!this.name) {
        result.isValid = false;
        result.message = 'Vui lòng nhập tên bảng lương';
        return result;
      }
      if (!this.selectedType) {
        result.isValid = false;
        result.message = 'Vui lòng nhập loại bảng lương';
        return result;
      }
      if (!this.selectedMonth) {
        result.isValid = false;
        result.message = 'Vui lòng nhập tháng lương';
        return result;
      }
      if (!this.importItems.length) {
        result.isValid = false;
        result.message = 'Vui lòng nhập dữ liệu cho bảng lương';
        return result;
      }

      return result;
    },
    createPayload() {
      const importedItems = this.importItems.filter(
        (item) => item.employeeCode,
      );
      importedItems.map((item) => {
        item.salaryMonth = this.selectedMonth;
        item.name = this.name;
        item.payrollTypeId = this.selectedType;
      });

      const targetReports = this.targetReportValues.map((item) => ({
        targetReportValue: item,
        salaryMonth: this.selectedMonth,
        name: this.name,
        payrollTypeId: this.selectedType,
      }));
      return {
        importedItems,
        targetReports,
        type: this.selectedType,
      };
    },
    onSubmit() {
      const { isValid, message } = this.validate();
      if (!isValid) {
        return makeToastFaile(message);
      }
      const payload = this.createPayload();

      ApiService.post('/human-resources/payroll', payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            this.$router.push({
              path: '/human-resources',
            });
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          const { status, message } = response.data;
          if (status === 0) {
            makeToastFaile(message);
          }
        });
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.importItems = Object.values(data.rows).map((item) => {
        return {
          ...item,
        };
      });
      this.fields = DEFAULT_FIELDS;

      if (this.selectedType === 7) {
        this.fields = data.fields;
        this.targetReportValues = data.values;
      }
    },
    clearFileExcel() {
      this.importItems = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
  },
};
</script>
